<template>
  <span style="height: 100vh" id="containerProduto">

      <v-row align="center" justify="center">
        <v-col cols="12">
          <img style="
            padding-top: 50%;
            display: block;
            margin-left: auto;
            margin-right: auto"
            src="/img/nick/product-alert.svg"
            class="alert"/>
          <img style="
            display: block;
            margin-left: auto;
            margin-right: auto"
            src="/img/nick/nick-umbrella.svg"
            class="nick"/>
        </v-col>
      </v-row>

<!--    <v-btn-->
<!--      color="whatsapp"-->
<!--      fab-->
<!--      fixed-->
<!--      bottom-->
<!--      right-->
<!--      id="chatTelegramButton"-->
<!--      class="elevation-5 white&#45;&#45;text"-->
<!--      @click="$util.linkNewTab('https://t.me/corretoravirtual_bot')"-->
<!--    >-->
<!--      <v-icon large>fab fa-whatsapp</v-icon>-->
<!--    </v-btn>-->

<!--    <v-btn color="secondary" fab fixed bottom left id="cartButton" class="elevation-5">-->
<!--      <v-badge overlap color="red">-->
<!--        <span slot="badge">{{badges.carrinho}}</span>-->
<!--        <v-icon medium >fas fa-shopping-cart</v-icon>-->
<!--      </v-badge>-->
<!--    </v-btn>-->

<!--    <listaProdutoCard :items="itemsFiltered" name="broker" />-->
  </span>
</template>

<script>
import { mapMutations, mapGetters, mapState } from "vuex";
// import listaProdutoCard from "./listaProdutoCard.vue";

export default {
  methods: {
    ...mapMutations(["showmsg"]),
    ...mapGetters(["mainSearch"]),
    loadItems() {
    }
  },
  computed: {
    ...mapState(["badges"]),
    itemsFiltered: function() {
      var thiz = this;
      return this.items.filter(function(gsxitem) {
        return thiz.$util
          .removeAccents(gsxitem.titulo)
          .toUpperCase()
          .includes(thiz.$util.removeAccents(thiz.mainSearch()).toUpperCase());
      });
    }
  },
  components: {
    // listaProdutoCard
  },
  mounted() {
    this.loadItems();
  },
  data() {
    return {
      dialog: false,
      chatTooltipTelegram: false,
      items: []
    };
  }
};
</script>

<style scoped>


#chatTelegramButton,
#cartButton {
  margin-bottom: var(--v-heightBottom) !important;
}

/* badge size */
#containerProduto .v-badge__badge {
  height: 18px !important;
  width: 18px !important;
  min-width: 18px !important;
}
#containerProduto .v-badge__badge span {
  font-size: 12px  !important;
}
#containerProduto .v-badge--overlap .v-badge__badge {
  top: -6px  !important;
  right: -6px  !important;
}
</style>